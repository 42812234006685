import React from "react";
import {
  get,
  reduce,
  sortBy,
  groupBy,
  toPairs,
  sumBy,
  map,
  first,
  last,
  find,
  isEmpty,
  compact,
} from "lodash";
import {
  VictoryChart,
  VictoryLine,
  VictoryLabel,
  VictoryAxis,
  VictoryArea,
  VictoryTheme,
} from "victory";

export default ({
  currentTierId,
  nextTierData,
  groups,
  appStyles,
  currencySymbol,
  T,
  className,
}) => {
  const currentTier = find(groups, { id: currentTierId }) || {};

  const paymentDetails =
    accumulatePaymentDetails(get(nextTierData, "paymentDetails")) || [];
  const hasPurchaseData = !isEmpty(paymentDetails);
  if (!currentTier || !hasPurchaseData) {
    return null;
  }

  const nextTier = find(groups, { id: nextTierData.groupId }) || {};
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      height={220}
      padding={32}
      className={className}
    >
      <VictoryArea
        sortKey="date"
        x="date"
        y="amount"
        interpolation="basis"
        labels={({ datum }) =>
          datum.date != last(paymentDetails).date
            ? currencySymbol + datum.amount.toFixed(1)
            : ""
        }
        labelComponent={<VictoryLabel renderInPortal dy={-8} dx={0} />}
        data={paymentDetails}
        style={{
          data: {
            fill: currentTier.badgeBackgroundColor || appStyles.accentColor,
          },
          labels: {
            fontSize: 8,
            fill: currentTier.badgeBackgroundColor,
          },
        }}
      />
      <VictoryArea
        standalone={false}
        sortKey="date"
        x="date"
        y="amount"
        interpolation="basis"
        labels={({ datum }) => ""}
        data={
          hasPurchaseData
            ? [
                last(paymentDetails),
                last(paymentDetails) && {
                  date: last(paymentDetails).date + 1,
                  amount: nextTierData.conditionalAmount,
                },
              ]
            : []
        }
        style={{
          data: {
            fill: hasPurchaseData && nextTier.badgeBackgroundColor,
            opacity: 0.4,
          },
        }}
      />
      <VictoryLine
        data={
          hasPurchaseData
            ? [
                {
                  x: first(paymentDetails).date,
                  y: last(paymentDetails).amount,
                },
                {
                  x: last(paymentDetails).date,
                  y: last(paymentDetails).amount,
                },
              ]
            : []
        }
        scale={{ x: "time", y: "linear" }}
        standalone={false}
        labelComponent={<VictoryLabel renderInPortal dx={-40} dy={-2} />}
        labels={({ datum }) =>
          datum.x === last(paymentDetails).date
            ? T("You are at ") +
              currencySymbol +
              (last(paymentDetails).amount || 0).toFixed(1) +
              "→"
            : ""
        }
        style={{
          data: {
            stroke: currentTier.badgeBackgroundColor,
            strokeWidth: 1,
            strokeDasharray: 2,
          },
          labels: {
            fill: currentTier.badgeBackgroundColor,
            fontSize: 8,
          },
        }}
      />

      <VictoryLine
        data={
          hasPurchaseData
            ? [
                {
                  x: first(paymentDetails).date,
                  y: nextTierData.conditionalAmount,
                },
                {
                  x: last(paymentDetails).date + 1,
                  y: nextTierData.conditionalAmount,
                },
              ]
            : []
        }
        scale={{ x: "time", y: "linear" }}
        standalone={false}
        labelComponent={<VictoryLabel renderInPortal dx={-40} dy={-2} />}
        labels={({ datum }) =>
          datum.x === last(paymentDetails).date + 1
            ? T("Reach ") +
              nextTier.name +
              T(" at ") +
              currencySymbol +
              datum.y.toFixed(1)
            : ""
        }
        style={{
          data: {
            stroke: nextTier.badgeBackgroundColor,
            strokeWidth: 1,
            strokeDasharray: 2,
          },
          labels: {
            fill: nextTier.badgeBackgroundColor,
            fontSize: 8,
          },
        }}
      />
      <VictoryAxis tickFormat={() => ""} />
    </VictoryChart>
  );
};

const accumulatePaymentDetails = (paymentDetails) => {
  const perDayPaymentDetails = map(
    toPairs(
      groupBy(
        paymentDetails,
        ({ date }) => new Date(new Date(date).toDateString()),
      ),
    ),
    ([date, payments]) => ({ date, amount: sumBy(payments, "amount") }),
  );
  const accumulatePayments = reduce(
    sortBy(perDayPaymentDetails, "date"),
    (acc, payment, index) => {
      return [
        ...acc,
        {
          date: payment.date,
          dateLabel: new Date(payment.date).getDate(),
          amount: payment.amount + get(acc[index - 1], "amount", 0),
        },
      ];
    },
    [],
  );
  return accumulatePayments;
};
