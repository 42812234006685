import React from "react";
import { connect } from "react-redux";
import {
  loadGifts,
  selectGiftForDonation,
  selectGiftForShare,
  resetShareGift,
  markGiftSeen,
  selectContactToShareGift,
  shareGiftDesktop,
  resetGiftsUnseenAmount,
  loadLoyaltyProfile,
} from "../store/user/actions";
import withTranslation from "../hocs/withTranslation";
import GiftsView from "../components/GiftsView";
import { selectGift, resetGiftRedeem } from "../store/giftRedeem/actions";
import { resetBranchId, startNewOrder } from "../store/order/actions";
import { getCurrentBranch, getStyles } from "../store/selectors";

const GiftsPage = (props) => <GiftsView {...props} />;

const mapStateToProps = (state, props) => {
  const { user, header, giftRedeem, order } = state;
  return {
    user,
    header,
    giftRedeem,
    order,
    currentBranch: getCurrentBranch(state, props),
    appStyles: getStyles(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: { menuData },
  } = props;

  return {
    loadGifts: () => dispatch(loadGifts()),
    selectGiftForShare: (giftId) => dispatch(selectGiftForShare(giftId)),
    selectGiftForDonation: (giftId) => dispatch(selectGiftForDonation(giftId)),
    selectGift: (gift) => dispatch(selectGift(gift, menuData)),
    resetGiftRedeem: () => dispatch(resetGiftRedeem()),
    resetShareGift: () => dispatch(resetShareGift()),
    markGiftSeen: (giftInstanceId) => dispatch(markGiftSeen(giftInstanceId)),
    selectContactToShareGift: (contactDetails) =>
      dispatch(selectContactToShareGift(contactDetails)),
    shareGiftDesktop: () => dispatch(shareGiftDesktop()),
    resetBranchId: () => dispatch(resetBranchId()),
    resetGiftsUnseenAmount: () => dispatch(resetGiftsUnseenAmount()),
    startNewOrder: () => dispatch(startNewOrder()),
    loadLoyaltyProfile: (force) => dispatch(loadLoyaltyProfile(force)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(GiftsPage));
